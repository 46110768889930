import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import Hero from '../components/landing/Hero';
import Wrapper from '../components/shared/Wrapper';
import ROOT_URL from '../constants/url';
import efd from '../components/newimages/efd.png';
import mr from '../components/newimages/mr.png';
import dy from '../components/newimages/dy.png';
import down from '../components/newimages/down.png';
import pt from '../components/newimages/pt.png';
import cj from '../components/newimages/cj.png';

const Home = () => {
  const { _t } = useTranslation();
  return (
    <Wrapper>
      <Helmet>
        <title>Resume Builder | CareerSocius</title>
        <link rel="canonical" href={ROOT_URL} />
      </Helmet>
      <div className="pt-8 grid lg:grid-cols-2 lg:col-gap-12">
        <div className="headermainpage">
          <Hero></Hero>
        </div>
        <div>
          <Container className="columnsfor">
            <div className="pt-8 grid lg:grid-cols-2 lg:col-gap-12">
              <div className='infobox'>
                <img alt = "image" src={efd} width = "150"></img>
                  <h1>Free Resume Builder</h1>
                  <p className="maintext">Quick and customizable resume design, free to use with localized templates, optimised for applicaiton tracking systems (ATS)</p>
              </div>
              <div className='infobox'>
                <img alt = "image" src={mr} width = "105"></img>
                  <h1>Download and Export Your Resume</h1>
                  <p className="maintext">Save & download your resume for free and ask your friends for feedback with a shareable link</p>
              </div>
              <div className='infobox'>
                <img alt = "image" src={pt} width = "125"></img>
                  <h1>Career Journaling App</h1>
                  <p className="maintext"> Record your thoughts, experiences, and takeaways for easy resume incorporation</p>
              </div>
              <div className='infobox'>
                <img alt = "image" src={dy} width = "150"></img>
                  <h1>Discover Yourself through Journaling</h1>
                  <p className="maintext">Try the free guided journaling activities to discover yourself</p>
              </div>
            </div>
            <div className="findoutmore">
              <a target="_blank" href="https://www.careersocius.com/">
                Find out more about us!
              </a>
            </div>
          </Container>
        </div>
        <Container>
        <footer className="footermain">
          <p className="text-primary-500">
            Developed by{' '}
            <a href="https://www.careersocius.com/">
              CareerSocius
            </a>
            {" "} & {" "}
            <a href="https://trycatchclub.github.io/#/projects/careersocius">
              Try&#123;Catch&#125;
            </a>
          </p>
        </footer>
        </Container>
      </div>
    </Wrapper>
  );
};

export default memo(Home);
