import { navigate, Link } from 'gatsby';
import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from '../../hooks/useUser';
import ModalContext from '../../contexts/ModalContext';
import Button from '../shared/Button';
import Logo from '../shared/Logo';

import logoheader from '../newimages/logoCSlanding.png';

const Hero = () => {
  const { t } = useTranslation();
  const { emitter, events } = useContext(ModalContext);
  const { user, isLoading } = useUser();

  const handleLogin = () => emitter.emit(events.AUTH_MODAL);

  const handleGotoApp = () => navigate('/app/timeline');

  return (
    <div className="flex flex-col md:flex-row items-center mt-24">
      <Link to="/">
        <img alt = "image" src={logoheader} width = "256px"></img>
        <div className="mt-12">
          {user ? (
            <Button
              onClick={handleGotoApp}
              isLoading={isLoading}
              className="loginbuttonmain"
            >
              {t('landing.hero.goToApp')}
            </Button>
          ) : (
            <Button
              onClick={handleLogin}
              isLoading={isLoading}
              className="loginbuttonmain"
            >
              {t('shared.buttons.login')}
            </Button>
          )}
        </div>
      </Link>
    </div>
  );
};

export default memo(Hero);
